import axios from "axios";

class AssetService {
    createAssets(payload: any) {
        return axios.post("/asset/list/", payload);
    }
    updateAssets(payload: any, id: number) {
        return axios.put(`/asset/list/${id}/`, payload)
    }
    getAllAssets() {
        return axios.get('/asset/list/')
    }
    getAssetClass(){
        return axios.get('/asset/asset-class/')
    }
    getAssetType(parent_id:any){
        return axios.get(`asset/asset-type/?parent=${parent_id}`)
    }
    createAssetClass(payload:any){
        return axios.post('/asset/asset-class/', payload)
    }
    updateAssetClass(id:any, payload:any){
        return axios.put(`/asset/asset-class/${id}/`, payload)
    }
}
export default new AssetService();