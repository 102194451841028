// import axios from "../common-axios";
import axios from "axios";

class AccountService {
  getAll() {
    return axios.get("/finance/get-account/");
  }
  getAccountInHierarchy() {
    return axios.get("/finance/get-account-hierarchy/");
  }
  getChildsOfAccount(parent_code_id: any) {
    return axios.get(`/finance/accounts-child/?parent_code_id=${parent_code_id}`);
  }
  getAccountGroup() {
    return axios.get("/finance/account-group/");
  }

  getByTitle(title: any) {
    return axios.get(`/finance/get-account-node/?title=${title}`);
  }

  getByTitleMaster(title: any) {
    return axios.get(`/finance/get-account-master/?title=${title}`);
  }
  getByTitleNode(title: any) {
    return axios.get(`/finance/get-account-node/?title=${title}`);
  }

  getAccounts() {
    return axios.get(`/finance/get-account-node/`);
  }


  getTransaction(param: any, doc: any) {
    return axios.post(`/finance/create-transaction-jv/`, param).then((response) => {
      if (doc !== null) {
        let formData = new FormData();
        formData.append('transaction_id', response.data.code);
        formData.append('document_file', doc);
        axios.patch('/finance/create-transaction-jv/', formData)
      }
      return response
    });
  }

  getByParent(parent: any) {
    return axios.get(`/finance/get-account/?parent=${parent}`);
  }

  createAccount(data: any) {
    return axios.post("/finance/create-account/", data);
  }

  updateAccount(data: any) {
    return axios.post("/finance/update-account/", data);
  }

  getchild(id: string) {
    return axios.get(`/accounts/get-child/${id}`);
  }

  getchartchildren() {
    return axios.get(`/accounts/get-children/`);
  }

  get(id: string) {
    return axios.get(`/accounts/chart/${id}`);
  }



  update(id: string, data: any) {
    return axios.put(`/accounts/chart/${id}/`, data);
  }

  delete(id: string) {
    return axios.delete(`/accounts/chart/${id}`);
  }

  deleteAll() {
    return axios.delete(`/accounts/chart/`);
  }

  findByTitle(title: string) {
    return axios.get(`/accounts/chart/?title=${title}`);
  }

  bulkImportOpeningBalances(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post('/finance/import-opening-balances/', formData)
  }

  bulkImportChartOfAccount(file: any) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post('/finance/import-accounts-in-bulk/', formData)
  }
  getAccountMapping() {
    return axios.get('/finance/account-mapping/')
  }
  saveAccountMapping(data: any) {
    return axios.post('/finance/account-mapping/', data)
  }
  
  confirmPayroll(data: any) {
    return axios.post(`/finance/employee-payroll-confirm-for-jv/`, data)
  }
  postPayroll(data: any) {
    return axios.post(`/finance/employee-payroll-post-for-jv/`, data)

  }
  checkAccountExist(title: string, code: number) {
    return axios.get(`/finance/check-account-exist/?title=${title}&code=${code}`);
  }
  getAccountMasterExclSeparated(title:string){
    return axios.get(`/finance/get-account-master-excl-separated/?title=${title}`)
  }
  getSupplierAccount(title:string){
    return axios.get(`/finance/get-supplier-accounts/?title=${title}`)

  }
}


export default new AccountService();