import axios from "axios";
class PurchaseService {
    createCurrencyRate(payload:any){
        return axios.post("/purchase/currency-rate/", payload);
    }
    updateCurrencyRate(id:any, payload:any){
        return axios.put(`/purchase/currency-rate/${id}/`, payload);
    }
    getAllCurencies(){
        return axios.get(`/purchase/currency-rate/`);
    }
    createPurchaseRequest(payload: any) {
        return axios.post("/purchase/purchase-request/", payload);
    }
    uploadPurchaseDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-request/${id}/`, formData);
    }

      
    getAllPurchaseRequest(){
        return axios.get(`/purchase/purchase-request-retrieve/`);
    }
    getPurchaseRequest(req_no:string){
        return axios.get(`/purchase/purchase-request-retrieve/${req_no}/`);
    }
    cancelPurchaseRequest(id:number){
        return axios.put(`/purchase/purchase-request-cancellation/${id}/`);
    }

    createEnquiryRequest(payload: any) {
        return axios.post("/purchase/purchase-enquiry/", payload);
    }
    uploadEnquiryDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-enquiry/${id}/`, formData);
    }
    getAllEnquiryRequest(){
        return axios.get(`/purchase/purchase-enquiry-retrieve/`);
    }
    getEnquiryRequest(req_no:string){
        return axios.get(`/purchase/purchase-enquiry-retrieve/${req_no}/`);
    }
    cancelEnquiryRequest(id:number){
        return axios.put(`/purchase/purchase-enquiry-cancellation/${id}/`);
    }

    createPurchaseQuotation(payload:any){
        return axios.post(`/purchase/purchase-quotation/`, payload);
    }
    uploadPurchaseQuotationDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-quotation/${id}/`, formData);
    }
    getAllQuotationRequest(){
        return axios.get(`/purchase/purchase-quotation-retrieve/`);
    }
    getQuotationRequest(quo_no:string){
        return axios.get(`/purchase/purchase-quotation-retrieve/${quo_no}/`);
    }
    cancelQuotationRequest(id:number){
        return axios.put(`/purchase/purchase-quotation-cancellation/${id}/`);
    }
    createPurchaseOrder(payload:any){
        return axios.post(`/purchase/purchase-order/`, payload);
    }
    uploadPurchaseOrderDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-order/${id}/`, formData);
    }
    getAllOrdersRequest(){
        return axios.get(`/purchase/purchase-order-retrieve/`);
    }
    getOrderRequest(quo_no:string){
        return axios.get(`/purchase/purchase-order-retrieve/${quo_no}/`);
    }
    cancelOrderRequest(id:number){
        return axios.put(`/purchase/purchase-order-cancellation/${id}/`);
    }
    createPurchaseReceipts(payload:any){
        return axios.post(`/purchase/purchase-receipts/`, payload);
    }
    uploadPurchaseReceiptDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-receipts/${id}/`, formData);
    }
    getAllReceiptRequest(){
        return axios.get(`/purchase/purchase-receipts-retrieve/`);
    }
    getReceiptRequest(quo_no:string){
        return axios.get(`/purchase/purchase-receipts-retrieve/${quo_no}/`);
    }
    cancelReceiptRequest(id:number){
        return axios.put(`/purchase/purchase-receipts-cancellation/${id}/`);
    }
    createPurchaseInvoice(payload:any){
        return axios.post(`/purchase/purchase-invoice/`, payload);
    }
    uploadPurchaseInvoiceDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-invoice/${id}/`, formData);
    }
    getAllInvoiceRequest(){
        return axios.get(`/purchase/purchase-invoice-retrieve/`);
    }
    getInvoicetRequest(quo_no:string){
        return axios.get(`/purchase/purchase-invoice-retrieve/${quo_no}/`);
    }
    cancelInvoiceRequest(id:number){
        return axios.put(`/purchase/purchase-invoice-cancellation/${id}/`);
    }
    returnPurchaseInvoice(payload:any){
        return axios.post(`/purchase/purchase-return/`, payload);
    }
    uploadPurchaseReturnDoc(file: any, id:any) {
        let formData = new FormData();
        formData.append('attachment', file);
        return axios.put(`/purchase/purchase-return/${id}/`, formData);
    }
}
export default new PurchaseService();